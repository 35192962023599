<template>
  <div>
    <v-card class="card-header">
      <v-select
        class="region-select px-3 pb-3 pt-4"
        :items="regionItems"
        :value="activeRegion"
        return-object
        :item-text="getRegionName"
        @change="changeRegionRoute"
        hide-details
        :label="$t('flagging.region')"
      ></v-select>
    </v-card>
    <v-card class="card-header">
      <v-select
        class="layer-select px-3 pb-3 pt-4"
        :items="layers"
        v-model="selectedLayer"
        item-text="title"
        return-object
        hide-details
        :label="$t('flagging.layer')"
      ></v-select>
    </v-card>
    <v-card class="card-header">
      <v-select
        class="timestep-select px-3 pb-3 pt-4"
        :items="timesteps"
        v-model="selectedTimestep"
        item-text="datetime"
        return-object
        hide-details
        prepend-icon="mdi-menu-left"
        @click:prepend="previousDate"
        append-icon="mdi-menu-right"
        @click:append="nextDate"
        prepend-inner-icon="mdi-delete"
        @click:prepend-inner="confirmationDialog = true"
        :label="$t('flagging.timestep')"
      >
      </v-select>
      <confirm-scene-delete-dialog
        v-if="confirmationDialog"
        :layer="selectedLayer"
        :selected-datetime="selectedTimestep"
        :dialog="confirmationDialog"
        :layer-selection="layers"
        :timesteps="timesteps"
        @closeDialog="confirmationDialog = false"
        @reloadDateTimes="reload"
      ></confirm-scene-delete-dialog>
    </v-card>
  </div>
</template>
<script>
import regionMixin from "@/core/mixins/region.mixin";
import ConfirmSceneDeleteDialog from "@/core/components/utils/ConfirmSceneDeleteDialog.vue";

export default {
  name: "FlaggingHeader",
  components: { ConfirmSceneDeleteDialog },
  mixins: [regionMixin],
  props: {
    map: Object,
    layer: Object,
    layers: Array,
    timesteps: Array,
    timestep: Object
  },
  data: () => ({
    analysisStation: true,
    confirmationDialog: false
  }),
  methods: {
    changeRegionRoute(item) {
      this.$router.push({
        name: this.$route.name,
        params: { regionId: item.id }
      });
    },
    nextDate() {
      if (
        this.timesteps.indexOf(this.selectedDatetime) !==
        this.timesteps.length - 1
      ) {
        const selection = this.timesteps;
        const selected = this.selectedTimestep;
        const selectedPeriodIndex = selection.indexOf(selected);
        if (selectedPeriodIndex > 0) {
          this.selectedTimestep = selection[selectedPeriodIndex - 1];
        } else if (selectedPeriodIndex === 0) {
          this.selectedTimestep = selection[selection.length - 1];
        }
      }
    },
    previousDate() {
      if (this.timesteps.indexOf(this.selectedDatetime) !== 0) {
        const selection = this.timesteps;
        const selected = this.selectedTimestep;
        const selectedPeriodIndex = selection.indexOf(selected);
        if (selectedPeriodIndex < selection.length - 1) {
          this.selectedTimestep = selection[selectedPeriodIndex + 1];
        }
      }
    },
    reload(index) {
      this.$emit("reloadDateTimes", index);
    }
  },
  computed: {
    disableNextButton() {
      if (this.datetimeItems) {
        return (
          this.datetimeItems.indexOf(this.selectedDatetime) ===
          this.datetimeItems.length - 1
        );
      }
      return false;
    },
    disablePreviousButton() {
      if (this.datetimeItems) {
        return this.datetimeItems.indexOf(this.selectedDatetime) === 0;
      }
      return false;
    },
    activeRegion() {
      return this.getActiveRegion(this.regions, this.$route.params.regionId);
    },
    regionItems() {
      return this.regions.features;
    },
    selectedLayer: {
      get() {
        return this.layer;
      },
      set(value) {
        this.$emit("changeLayer", value);
      }
    },
    selectedTimestep: {
      get() {
        return this.timestep;
      },
      set(value) {
        this.$emit("changeTimestep", value);
      }
    }
  }
};
</script>
<style scoped>
.region-select {
  z-index: 2;
  width: 25%;
  max-width: 300px;
  left: 1%;
  position: absolute;
  margin-top: 10px;
  background-color: rgba(255, 255, 255, 1);
}

.layer-select {
  z-index: 2;
  width: 25%;
  max-width: 300px;
  position: absolute;
  left: 28%;
  margin-top: 10px;
  background-color: rgba(255, 255, 255, 1);
}

.timestep-select {
  z-index: 2;
  width: 40%;
  max-width: 300px;
  position: absolute;
  left: 55%;
  margin-top: 10px;
  background-color: rgba(255, 255, 255, 1);
}

.card-header {
  width: 60%;
  max-width: 900px;
  position: absolute;
  z-index: 2;
}
</style>
