<template>
  <v-main>
    <div id="main-window">
      <div id="flagging-map" data-tap-disabled="true">
        <flagging-header
          v-if="!mapIsLoading"
          :regions="allRegions"
          :region="activeRegion"
          :layer="selectedLayer"
          :layers="layerSelection"
          :timestep="selectedTimestep"
          :timesteps="timestepSelection"
          @changeLayer="setSelectedLayer"
          @changeTimestep="setSelectedTimestep"
          @reloadDateTimes="reloadTimesteps"
        ></flagging-header>
        <flagging-tool
          v-if="!mapIsLoading"
          :map="map"
          :region="activeRegion"
          :timestep="selectedTimestep"
          :layer-selection-all="layerSelection"
          @reload="reloadMap"
        ></flagging-tool>
        <flagging-right-side-menu
          :map="map"
          @toggleLayer="toggleLayer"
          @toggleRGBLayer="toggleRGBLayer"
          @toggleVSLayer="toggleVSLayer"
          :layer="selectedLayer"
          :timestep="selectedTimestep"
        ></flagging-right-side-menu>
        <v-card
          width="260"
          :height="getWindowHeight()"
          elevation="0"
          v-if="selectedTimestep"
          class="meta-data-image "
        >
          <div
            class="justify-end mr-5 mt-2"
            v-if="!loading && selectedTimestep.resolution"
          >
            <v-row class="ml-8">
              <v-spacer></v-spacer>
              <v-icon v-if="!hideWindow" @click="hideWindow = !hideWindow"
                >mdi-chevron-down
              </v-icon>
              <v-icon v-else @click="hideWindow = !hideWindow"
                >mdi-chevron-up
              </v-icon>
            </v-row>
          </div>
          <div
            class="justify-end mr-5"
            v-if="selectedLayer && selectedTimestep.resolution"
          >
            <v-row class="ml-8">
              <v-col class="pb-1">{{ $t("metaData.sensor") }}:</v-col>
              <v-col class="pb-1 pl-0">{{ activeSensor }}</v-col>
            </v-row>
            <v-row class="ml-8 mb-2">
              <v-col class="pb-1"> {{ $t("metaData.resolution") }}:</v-col>
              <v-col
                class="pb-1"
                v-if="
                  selectedTimestep.sensor == 'DRONE' ||
                    selectedTimestep.sensor == 'modelled'
                "
              >
                ~{{ selectedTimestep.resolution.toFixed(2) }} m
              </v-col>
              <v-col class="pb-1" v-else>
                ~{{ selectedTimestep.resolution.toFixed(0) }} m
              </v-col>
            </v-row>
          </div>
          <img v-if="legendImage" :src="legendImage" class="legend-image" />
        </v-card>
        <div
          v-if="cursorPosition"
          class="font-weight-light cursor-position"
          style="font-size: 84%"
        >
          {{ cursorPosition }}
        </div>
        <div class=" pa-5 infofield-position" style="font-size: 84%">
          <v-row v-if="actualValue">
            <span>{{ actualValue }} {{ selectedLayer.unit }}</span>
          </v-row>
        </div>
      </div>
    </div>
  </v-main>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import regionMixin from "@/core/mixins/region.mixin";
import bbox from "@turf/bbox";
import mapbox from "mapbox-gl";
import MapboxConfig from "@/core/plugins/mapbox";
import MapboxUtils from "mapbox-gl-utils";
import mapboxMixins from "@/core/mixins/mapbox.mixins";
import coastLayerMixin from "@/core/mixins/coastLayer.mixin";
import rasterLayerMixin from "@/core/mixins/rasterLayer.mixin";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import StaticMode from "@mapbox/mapbox-gl-draw-static-mode";
import FlaggingHeader from "@/core/components/flagging/FlaggingHeader.vue";
import FlaggingTool from "@/core/components/flagging/FlaggingTool.vue";
import FlaggingRightSideMenu from "@/core/components/flagging/FlaggingRightSideMenu.vue";
import FreehandDrawServices from "@/core/services/freehand-draw.services";
import axios from "axios";

export default {
  name: "FlaggingView",
  mixins: [regionMixin, mapboxMixins, coastLayerMixin, rasterLayerMixin],
  components: {
    FlaggingRightSideMenu,
    FlaggingTool,
    FlaggingHeader
  },
  props: {
    place: { type: String, default: "visualize" }
  },
  data: () => ({
    mapIsLoading: true,
    dataloading: true,
    map: null,
    baseUrl: process.env.VUE_APP_BASE_URL,
    showTimelapse: false,
    sensorNames: [
      { val: "SENT2", label: "Sentinel-2" },
      { val: "SENT3", label: "Sentinel-3" },
      { val: "LSAT8", label: "Landsat 8" },
      { val: "LSAT5", label: "Landsat 5" },
      { val: "LSAT9", label: "Landsat 9" },
      { val: "SUDOV", label: "SuperDove" },
      { val: "VIIRS", label: "VIIRS" },
      { val: "SKYST", label: "SKYSAT" },
      { val: "ENMAP", label: "EnMap" },
      { val: "MODAQ", label: "Modis Aqua" },
      { val: "WV2", label: "WorldView2" },
      { val: "WV3", label: "WorldView3" },
      { val: "PLN", label: "PléiadesNeo" },
      { val: "DRONE", label: "Drone" },
      { val: "modelled", label: "Modelled" }
    ],
    activeSensor: null,
    legendImage: null,
    cursorPosition: null,
    cursorPositionValue: [],
    hideWindow: false,
    selectedRegion: {},
    layerSelection: [],
    selectedLayer: {},
    loading: true,
    timestepSelection: [],
    selectedTimestep: {},
    activeLayer: {},
    actualValue: null,
    overviewDialog: false
  }),
  computed: {
    ...mapState("management", ["regions"]),
    ...mapGetters("management", ["activeRegion"]),
    allRegions() {
      return this.regions.features;
    },
    mapStyle() {
      return this.getMapStyle();
    },
    boundingBox() {
      if (this.activeRegion) {
        return bbox(this.activeRegion);
      } else {
        return null;
      }
    }
  },
  methods: {
    ...mapActions("app", ["setVisMapLoaded"]),
    ...mapActions("app", ["showSnackbar"]),
    ...mapActions("management", ["getOrFetchRegions"]),
    initMap() {
      mapbox.accessToken = MapboxConfig.accessToken;
      this.map = new mapbox.Map({
        container: "flagging-map",
        style: this.mapStyle,
        bounds: this.boundingBox,
        transformRequest: url => {
          if (url.startsWith(this.baseUrl) && this.$keycloak.authenticated) {
            return {
              url: url,
              headers: { Authorization: `Bearer ${this.$keycloak.token}` }
            };
          }
        }
      });
      const scaleControl = new mapbox.ScaleControl({
        maxWidth: 150,
        unit: "metric"
      });
      this.map.addControl(scaleControl, "bottom-right");
      this.$store.visualizeMap = this.map;

      this.map.on("load", () => {
        MapboxUtils.init(this.map, mapbox);
        this.setVisMapLoaded(true);
        this.mapIsLoading = false;
        this.onMouseMove(this.map);
        this.onMouseClick(this.map);
        this.addDrawComponent(this.map);
        this.$emit("mapLoaded", this.map);
        this.map.getCanvas().style.cursor = "grab";
        this.map.resize();
        this.setFirstLayer();
      });
    },

    async setLegendImage(cmapName) {
      const response = await this.$rastless.get(`/cmaps/${cmapName}`);
      const image = response.data.legendImage;
      if (image) {
        this.legendImage = `data:image/png;base64,${image}`;
      }
    },
    async getActualValue(long, lat) {
      const response = await this.$rastless.post(
        `layers/${this.selectedLayer.layerId}/${this.selectedTimestep.datetime}/statistic?token=${this.accessToken}`,
        {
          geometry: {
            type: "Point",
            coordinates: [parseFloat(lat), parseFloat(long)]
          }
        }
      );
      return response.data.data;
    },
    onMouseMove(map) {
      map.on("mousemove", async e => {
        this.cursorPosition = `${e.lngLat.lat.toFixed(
          4
        )} ${e.lngLat.lng.toFixed(4)}`;
      });
    },
    onMouseClick(map) {
      map.on("click", async e => {
        this.actualValue = await this.getActualValue(
          e.lngLat.lat,
          e.lngLat.lng
        );
      });
    },
    addDrawComponent(map) {
      let modes = MapboxDraw.modes;
      modes.draw_free = FreehandDrawServices;
      modes.static = StaticMode;
      let draw = new MapboxDraw({
        modes: modes,
        displayControlsDefault: false,
        controls: {
          trash: true
        }
      });
      this.$store.draw = draw;
      map.addControl(draw);
      draw.changeMode("static");
      map.getCanvas().style.cursor = "pointer";
      return draw;
    },

    getWindowHeight() {
      if (!this.hideWindow) {
        if (this.activeRasterLayer.resolution && this.legendImage) {
          return 165;
        } else if (!this.activeRasterLayer.resolution && this.legendImage) {
          return 95;
        } else if (this.activeRasterLayer.resolution && !this.legendImage) {
          return 95;
        } else {
          return;
        }
      } else {
        return 25;
      }
    },
    async fetchLayers() {
      const client = this.$appConfig.keycloakClient;
      const response = await this.$rastless.get(
        `/layers?client=${client}&region_id=${this.activeRegion.id}&token=${this.accessToken}`
      );
      if (response.data.length > 0) {
        const data = response.data.sort((a, b) =>
          a.title.localeCompare(b.title)
        );
        const filteredLayer = data.filter(layer => layer.product !== "qut");
        this.layerSelection = filteredLayer;
        this.loading = false;
      }
    },
    async setFirstLayer() {
      const filteredSelection = this.layerSelection.filter(
        layer => layer.product == "chl"
      );
      if (filteredSelection.length) {
        this.selectedLayer = filteredSelection[0];
        await this.setLegendImage(this.selectedLayer.colormap);
      } else {
        this.selectedLayer = this.layerSelection[0];
      }
    },
    async reloadTimesteps(index) {
      await this.fetchLayers();
      this.stepLoading = true;
      this.timestepSelection = await this.fetchAllTimesteps(
        this.selectedLayer.layerId,
        false
      );
      if (index > 0) {
        this.selectedTimestep = this.timestepSelection[index - 1];
      } else {
        this.selectedTimestep = this.timestepSelection[index];
      }
      this.stepLoading = false;
    },

    reloadMap() {
      this.map.remove();
      this.initMap();
      this.map.on("load", () => {
        this.addLayer();
      });
    },
    async fetchTimesteps(timestep) {
      this.stepLoading = true;
      this.timestepSelection = await this.fetchAllTimesteps(
        this.selectedLayer.layerId,
        false
      );
      if (
        this.timestepSelection.findIndex(
          step => step.datetime === this.selectedTimestep.datetime
        ) <= 0
      ) {
        this.selectedTimestep = this.timestepSelection[0];
      } else {
        this.selectedTimestep = null;
        this.selectedTimestep = timestep;
      }
      this.stepLoading = false;
    },
    setSelectedLayer(layer) {
      this.selectedLayer = layer;
    },
    setSelectedTimestep(timestep) {
      this.selectedTimestep = timestep;
    },
    addLayer() {
      const rgbLayer = this.layerSelection.filter(
        layer => layer.product === "rgb"
      )[0];
      if (rgbLayer) {
        this.addRasterLayer(
          "rasterBackground",
          rgbLayer.layerId,
          this.selectedTimestep,
          "z-index-2",
          this.accessToken
        );
      }
      this.addRasterLayer(
        "raster",
        this.selectedLayer.layerId,
        this.selectedTimestep,
        "z-index-2",
        this.accessToken
      );
    },
    toggleLayer(showLayer) {
      if (showLayer) {
        this.$store.visualizeMap.U.showSource("rasterSource");
      } else {
        this.$store.visualizeMap.U.hideSource("rasterSource");
      }
    },
    toggleRGBLayer(showLayer) {
      if (showLayer) {
        this.$store.visualizeMap.U.showSource("rasterBackgroundSource");
      } else {
        this.$store.visualizeMap.U.hideSource("rasterBackgroundSource");
      }
    },
    toggleVSLayer(showLayer) {
      if (showLayer) {
        this.$store.visualizeMap.U.showSource("virtualStationSource");
      } else {
        this.$store.visualizeMap.U.hideSource("virtualStationSource");
      }
    },
    async fetchPermanentVirtualStations() {
      try {
        const response = await axios.get(
          `/userdata/virtual-stations/?region=${this.$route.params.regionId}`
        );
        let stationArray = response.data;
        this.addPermanentStationsToMap(stationArray);
      } catch {
        this.showSnackbar({
          show: true,
          message: this.$t("errorMessage"),
          color: "error"
        });
      }
    },
    addPermanentStationsToMap(stationArray) {
      const featureCollection = {
        features: [],
        type: "FeatureCollection"
      };
      stationArray.forEach(station => {
        const feature = {
          id: station.id,
          type: "Feature",
          properties: {},
          geometry: station.geometry
        };
        featureCollection.features.push(feature);
      });
      this.map.addSource(`virtualStationSource`, {
        type: "geojson",
        data: featureCollection
      });
      this.map.addLayer({
        id: "regionsOutline",
        type: "line",
        source: "virtualStationSource",
        minzoom: 6,
        paint: {
          "line-color": "white",
          "line-width": 2
        }
      });
    }
  },

  watch: {
    async activeRegion() {
      await this.fetchLayers();
      await this.setFirstLayer();
      this.dataloading = false;
    },
    async selectedLayer() {
      if (this.selectedLayer) {
        await this.fetchTimesteps(this.selectedTimestep);
      }
    },
    selectedTimestep() {
      if (this.selectedTimestep?.resolution) {
        this.addLayer();
        const activeSensor = this.sensorNames.filter(
          object => object.val == this.selectedTimestep.sensor
        );
        if (activeSensor != null) {
          this.activeSensor = activeSensor[0].label;
        } else {
          this.activeSensor = this.selectedTimestep.sensor;
        }
      }
    },
    "selectedLayer.colormap": {
      handler(cmapName) {
        if (cmapName) {
          this.setLegendImage(cmapName);
        } else {
          this.legendImage = null;
        }
      },
      immediate: true
    }
  },
  async created() {
    this.getOrFetchAccessToken().then(async () => {
      await this.fetchLayers();
    });
    this.fetchPermanentVirtualStations();
  },
  mounted() {
    this.$watch(
      "boundingBox",
      bbox => {
        if (bbox) {
          this.initMap();
        }
      },
      {
        immediate: true
      }
    );
  },
  beforeDestroy() {
    this.setVisMapLoaded(false);
  }
};
</script>

<style scoped>
#main-window {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

#flagging-map {
  width: 100%;
  height: 100%;
}

.legend-image {
  width: 240px;
  padding: 5px;
  z-index: 2;
}

.meta-data-image {
  position: absolute;
  bottom: 0px;
  left: 0px;
  border-radius: 0 0 0 0;
  background-color: rgba(250, 250, 250, 0.5);
  padding: 5px;
  z-index: 2;
}

.cursor-position {
  z-index: 2;
  position: absolute;
  bottom: 0px;
  right: 280px;
  background-color: hsla(0, 0%, 100%, 0.5);
  width: 100px;
  padding-left: 6px;
}

.infofield-position {
  z-index: 2;
  position: absolute;
  bottom: 60px;
  height: 10px;
  right: 10px;
  background-color: hsla(0, 0%, 100%, 0.7);
  width: 80px;
  padding-left: 6px;
}

.map-overlay {
  z-index: 2;
  font: 12px/20px "Helvetica Neue", Arial, Helvetica, sans-serif;
  position: absolute;
  width: 40%;
  bottom: 00px;
  left: 260px;
  margin: 0px;
  padding: 0px;
}

.map-overlay .map-overlay-inner {
  background-color: rgba(250, 250, 250, 0.5) !important;
  z-index: 2;
  padding: 10px;
}

.map-overlay input {
  z-index: 2;
  background-color: transparent;
  display: inline-block;
  width: 100%;
  position: relative;
  margin: 0;
  cursor: ew-resize;
}

.legend-image {
  width: 240px;
  padding: 5px;
  z-index: 2;
}
</style>
